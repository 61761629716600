import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import Image from "../components/image"
import SEO from "../components/seo"

import "../css/pages/home.scss"

const IndexPage = () => (
  <Layout showFooter={false}>
    <SEO title="Home" keywords={[`vitamin`, `games`, `project`, `arrhythmia`]} />
    <div className="home">
      <div className="hero">
        <div className="video-container">
          {/* <video autoPlay={true} muted={true} loop={true} className="video" src={videoTmp} type="video/mp4">
            <p>Your browser does not support the video element.</p>
          </video> */}
        </div>
        <div className="overlay">
          <div className="logo">
            <div className="img">
              <Image filename={"logos/project_arrhythmia_logo.png"}/>
            </div>
            <h1>Project Arrhythmia</h1>
            <Link to="/project-arrhythmia">
              <div className="button">Play the Game</div>
            </Link>
          </div>
        </div>
      </div>
    </div>
  </Layout>
)

export default IndexPage
